import { set } from 'lodash';
import React, { useState, useEffect } from 'react';

const LoyaltyTiers = ({ lang, expandTiers }) => {
  const [showTiers, setShowTiers] = useState(true);
  const [hideExpander, setHideExpander] = useState(false);

  const copyStrings = {
    en: {
      heading: 'What’s my Visionary Club tier?',
      tableHeading: 'Benefits',
      tableColHeader1: 'Eyewear Enthusiast',
      tableColDesc1: '0-100 Points',
      tableColHeader2: 'Lens Legends',
      tableColDesc2: '101-300 Points',
      tableColHeader3: 'Optical Elite',
      tableColDesc3: '300+ points+',
      tableRowDesc1: 'Free Shipping',
      tableRowDesc2: 'Enter Member’s Only Contests',
      tableRowDesc3: 'Free Sunglasses Case',
      tableRowDesc4: 'Exclusive Access to New Drops',
      tableRowDesc5: 'Free Sunglasses',
    },
  };

  const toggleTiers = () => {
    setShowTiers(!showTiers);
  };

  useEffect(() => {
    // on mount
    if (expandTiers) {
      setShowTiers(true);
      setHideExpander(true);
    }
  }, []);

  return (
    <div className={!hideExpander ? 'custom-tiers__breakdown' : 'custom-tiers__breakdown -slim'}>
      <div className={!hideExpander ? 'custom-tiers__inner' : 'custom-tiers__inner -slim'}>
        {!hideExpander
        && (
        <div className="custom-tiers__heading" onClick={(e) => toggleTiers()}>
          {copyStrings[lang].heading}
          <a><img className={showTiers ? '-rotate' : ''} src="https://assets.heyethos.com/bonlook/landing/icon-down-arrow.png" alt="What’s my Trendsetter tier?" /></a>
        </div>
        )}
        {showTiers
        && (
        <div className={!hideExpander ? 'custom-tiers__table' : 'custom-tiers__table -slim'}>
          <table>
            <tr>
              <th>{copyStrings[lang].tableHeading}</th>
              <th>
                {copyStrings[lang].tableColHeader1}
                <span>{copyStrings[lang].tableColDesc1}</span>
              </th>
              <th>
                {copyStrings[lang].tableColHeader2}
                <span>{copyStrings[lang].tableColDesc2}</span>
              </th>
              <th>
                {copyStrings[lang].tableColHeader3}
                <span>{copyStrings[lang].tableColDesc3}</span>
              </th>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc1}</td>
              <td><img className="custom-tiers__table--star" src="https://assets.heyethos.com/vincepharaoh/bullet-glasses.png" alt="Star" /></td>
              <td><img className="custom-tiers__table--star" src="https://assets.heyethos.com/vincepharaoh/bullet-glasses.png" alt="Star" /></td>
              <td><img className="custom-tiers__table--star" src="https://assets.heyethos.com/vincepharaoh/bullet-glasses.png" alt="Star" /></td>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc2}</td>
              <td><img className="custom-tiers__table--star" src="https://assets.heyethos.com/vincepharaoh/bullet-glasses.png" alt="Star" /></td>
              <td><img className="custom-tiers__table--star" src="https://assets.heyethos.com/vincepharaoh/bullet-glasses.png" alt="Star" /></td>
              <td><img className="custom-tiers__table--star" src="https://assets.heyethos.com/vincepharaoh/bullet-glasses.png" alt="Star" /></td>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc3}</td>
              <td></td>
              <td><img className="custom-tiers__table--star" src="https://assets.heyethos.com/vincepharaoh/bullet-glasses.png" alt="Star" /></td>
              <td><img className="custom-tiers__table--star" src="https://assets.heyethos.com/vincepharaoh/bullet-glasses.png" alt="Star" /></td>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc4}</td>
              <td></td>
              <td><img className="custom-tiers__table--star" src="https://assets.heyethos.com/vincepharaoh/bullet-glasses.png" alt="Star" /></td>
              <td><img className="custom-tiers__table--star" src="https://assets.heyethos.com/vincepharaoh/bullet-glasses.png" alt="Star" /></td>
            </tr>
            <tr>
              <td>{copyStrings[lang].tableRowDesc5}</td>
              <td></td>
              <td></td>
              <td><img className="custom-tiers__table--star" src="https://assets.heyethos.com/vincepharaoh/bullet-glasses.png" alt="Star" /></td>
            </tr>
          </table>
        </div>
        )}
      </div>
    </div>
  );
};
export default LoyaltyTiers;
